import { jwtDecode } from "jwt-decode";

export function isTokenValid(token) {
  if (!token) return { valid: false, error: "Token is empty" };

  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
      return { valid: false, error: "Token is expired" };
    }

    return { valid: true, decoded };
  } catch (err) {
    return { valid: false, error: "Invalid token" };
  }
}

export const fetcher = async (endpoint, method, payload = null) => {
  const token = getToken();
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `bearer ${token}`);
  // let url = `http://127.0.0.1:8000/${endpoint}`;
  let url = `https://onboardifyapi.tasc360.com/${endpoint}`;
  // let url = `https://onboardifyapi.uae.tasc360.com/${endpoint}`;
  let requestOptions = {
    method,
    headers: myHeaders,
  };
  if (payload) {
    requestOptions.body = payload;
  }
  try {
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    // if(token !== null && token.length > 0 && data.hasOwnProperty('message') && data.message === 'Unauthenticated.'){
    //   sessionStorage.clear();
    //  window.location.href = 'https://governify.tasc360.com/signin';
    // }
    if (data.hasOwnProperty("message") && data.message === "Unauthenticated.") {
      sessionStorage.setItem("redirectUrl", window.location.href);
      window.location.href = window.location.origin + "/signin";
    }

    return data;
  } catch (err) {
    console.log(err, "err");
  }
};

export const getRole = () => {
  let role = sessionStorage.getItem("role");
  return role;
};

export const getToken = () => {
  let token = sessionStorage.getItem("token");

  return token;
};

export function getDateAndTime(time) {
  let date = new Date(time);
  let day = date.getDate();
  let month = date.toLocaleString("default", { month: "long" });
  let year = date.getFullYear();
  let hour = date.getHours();
  let minutes = date.getMinutes();

  let newDate = `${day} ${month.slice(0, 3)} ${year} at ${hour}:${
    minutes < 10 ? "0" + minutes : minutes
  }`;
  return newDate;
}

export function getFirstLettersOfName(value) {
  if (value === undefined || value === null) {
    return "";
  }
  let name = value.split(" ");
  let firstLetters = "";
  name.forEach((item, index) => {
    if (index < 2) firstLetters += item[0].toUpperCase();
  });

  return firstLetters;
}

export function updateProtectedLinks(htmlString, assets = null) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const links = doc.querySelectorAll("a");
  const img = doc.querySelectorAll("img");
  const allElements = doc.body.querySelectorAll("*");
  // console.log('links', links, assets);
  if (links) {
    links.forEach((item) => {
      if (item.href.includes("protected_static")) {
        if (assets) {
          assets.forEach((asset) => {
            if (item.href.toLowerCase() === asset.url.toLowerCase()) {
              item.href = asset.public_url;
            }
          });
        }
      }
    });
  }
  if (img) {
    img.forEach((item) => {
      item.style.width = "100%";
      item.style.height = "auto";
    });
  }

  // Remove empty elements except images
  allElements.forEach((item) => {
    if (item.tagName.toLowerCase() !== "img" && !item.innerHTML.trim()) {
      item.remove();
    }
  });

  return doc.body.innerHTML; // Return updated HTML as string
}

export function extractUsernameFromMessage(value, assets = null) {
  let message = "";
  let updatedHtml = updateProtectedLinks(value, assets);
  let newValue = updatedHtml.split(":");
  if (value.includes(sessionStorage.getItem("userEmail"))) {
    newValue.forEach((msg, i) => {
      if (i !== 0) {
        message += msg.replace("https", "https:");
      }
    });
  } else {
    message = updatedHtml;
  }
  return message;
}

export function showUserName(value) {
  let userName = "";
  if (value.includes(sessionStorage.getItem("userEmail"))) {
    userName = sessionStorage.getItem("userName");
  } else {
    userName = "Governify Team";
  }
  return userName;
}

export function appendEmoji(value, emoji) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(value, "text/html");

  // Find all <p> tags
  const pTags = doc.querySelectorAll("p");

  // Append emoji to the last <p> tag
  if (pTags.length > 0) {
    let lastPTag = pTags[pTags.length - 1];
    if (lastPTag.innerHTML.trim() === "<br>") lastPTag.innerHTML = "";
    lastPTag.append(emoji);
  }

  // Convert the modified document back to a string
  const modifiedHtmlString = doc.body.innerHTML;
  return value === "" ? emoji : modifiedHtmlString;
}

export function setUserDataCookie(data) {
  const jsonData = JSON.stringify(data);
  document.cookie = `userData=${encodeURIComponent(
    jsonData
  )}; path=/; max-age=1296000`; // Set for 15 days
}

function getCookie(name) {
  const cookieArray = document.cookie.split("; ");
  for (let cookie of cookieArray) {
    const [cookieName, cookieValue] = cookie.split("=");
    if (cookieName === name) return decodeURIComponent(cookieValue);
  }
  return null;
}

export function getUserDataFromCookie() {
  const data = getCookie("userData");
  return data ? JSON.parse(data) : null;
}

export function isUserVerified(userId, browserName, system) {
  const userData = getUserDataFromCookie();
  if (!userData) return false;

  const user = userData.find((user) => user.userId == userId);
  if (!user) return false;

  return user.user.some(
    (session) =>
      session.browserName === browserName && session.system === system
  );
}

export function getBrowserInfo() {
  let userAgent = navigator.userAgent;
  let browserName, osType;

  // Determine browser name
  if (userAgent.indexOf("Firefox") > -1) {
    browserName = "Mozilla Firefox";
  } else if (
    userAgent.indexOf("Chrome") > -1 &&
    userAgent.indexOf("Edg") === -1
  ) {
    browserName = "Google Chrome";
  } else if (
    userAgent.indexOf("Safari") > -1 &&
    userAgent.indexOf("Chrome") === -1
  ) {
    browserName = "Apple Safari";
  } else if (userAgent.indexOf("Edg") > -1) {
    browserName = "Microsoft Edge";
  } else if (userAgent.indexOf("Trident") > -1) {
    browserName = "Internet Explorer";
  } else {
    browserName = "Unknown Browser";
  }

  // Determine OS type
  if (userAgent.indexOf("Win") > -1) {
    osType = "Windows";
  } else if (userAgent.indexOf("Mac") > -1) {
    osType = "MacOS";
  } else if (userAgent.indexOf("Linux") > -1) {
    osType = "Linux";
  } else if (/Android/i.test(userAgent)) {
    osType = "Android";
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    osType = "iOS";
  } else {
    osType = "Unknown OS";
  }

  return {
    browser: browserName,
    os: osType,
  };
}

export function getDifferenceBetweenCurrentDate(inputDate) {
  const previousDate = new Date(inputDate);
  const currentDate = new Date();

  const diffTime = Math.abs(currentDate - previousDate); // Difference in milliseconds
  const diffHours = diffTime / (1000 * 60 * 60); // Convert to hours

  if (diffHours < 23) {
    return `${Math.floor(diffHours)}h`;
  } else {
    return `${Math.ceil(diffHours / 24) - 1}d`;
  }
}