/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { Profile } from "../../../utils/Icons";
import { fetcher } from "../../../utils/helper";
import { toast } from "react-toastify";
import { Loader } from "../../common/Loader";
import { getLoginUserDetails } from "../../../utils/tools";

const NameChangeModal = ({ open, setOpen, setUserName }) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async () => {
    if (name === "") {
      setError(true); 
      return;
    } else {
      setError(false);
    }
    let url = "governify/customer/updateName";
    let method = "POST";
    let payload = JSON.stringify({
      updated_name: name,
    });

    setLoading(true);
    try {
      const res = await fetcher(url, method, payload);
      if (res.status) {
        await getLoginUserDetails();
        toast.success(res.message);
        setTimeout(() => {
          setLoading(false);
          setOpen(false);
          // window.location.reload();
        }, 3000);
      } else {
        toast.error(res.message);
        // setTimeout(() => {
        setLoading(false);
        // window.location.reload();
        // }, 3000);
      }
    } catch (error) {
      toast.error("Something went wrong!");
      setLoading(false);
    }
  };

  useEffect(() => {
    if(sessionStorage.getItem("userName")) {
      setName(sessionStorage.getItem("userName"));
      setUserName(sessionStorage.getItem("userName"));
    }
  },[sessionStorage.getItem("userName")])

  return (
    <>
      {loading && <Loader />}
      <Modal
        open={open}
        footer={null}
        zIndex={9992}
        centered
        onCancel={() => setOpen(false)}
      >
        <div>
          <div className="fs-24 fw-bold text-center">Edit your full name</div>
          <div
            style={{ marginTop: "6px", color: "#6D7175" }}
            className="fs-16 text-center"
          >
            Update your full name as it appears on your profile.
          </div>
          <div
            style={{ marginTop: "24px", gap: "8px" }}
            className="d-flex flex-column"
          >
            <div className="fs-16" style={{ color: "#202223" }}>
              Full name
            </div>
            <Input
              type="text"
              prefix={<Profile width="16px" height="20px" />}
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter full name"
              className="profile-input"
            />
            {error && (
              <span style={{ color: "#FD5749" }}>
                Please complete this field.
              </span>
            )}
          </div>
          <div style={{ marginTop: "24px" }}>
            <button
              style={{
                background: "#00BF63",
                color: "#fff",
                width: "100%",
                padding: "13px",
                border: "none",
                fontWeight: "600",
                borderRadius: "8px",
              }}
              onClick={() => handleSubmit()}
            >
              Update
            </button>
          </div>
        </div>
      </Modal>
      {/* <ToastContainer position="bottom-right" className="custom-toast" /> */}
    </>
  );
};

export default NameChangeModal;
