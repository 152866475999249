import { Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { countryCode } from "../../utils/country";
import getCountrySVG from "react-country-svg-flag";

const RegisterNumberInput = ({ formData, setFormData, filterOption }) => {
    const countryCodes = countryCode;
  const [countryOption, setCountryOption] = useState([]);
  // const [phoneCode, setPhoneCode] = useState("+971");
  // const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (countryCodes.length && countryOption.length === 0) {
      let option = [];
      countryCodes.map((item) => {
        let countrySvg = getCountrySVG(item.country.toLowerCase());
        option.push({
          label: (
            <div className="countryOption ff-ws" style={{ gap: "5px" }}>
              {item.countryCodes[0] + " "}
              <span
                dangerouslySetInnerHTML={{
                  __html: countrySvg,
                }}
                className="tw-max-w-[20px] tw-h-[20px]"
                style={{ marginLeft: "auto" }}
              ></span>
            </div>
          ),
          value: item.countryCodes[0],
        });
      });
      setCountryOption(option);
    }
  }, []);
  return (
    <div
      className="mobile-inp-field-register"
      style={{
        borderColor: "#C9CCCF",
        // border: "1px solid",
        border: "none",
        borderRadius: "50px",
        padding: "4px 5px",
        display: "flex",
        background: "rgb(232, 240, 254)",
      }}
    >
      <Select
        value={formData.phone_code}
        style={{ width: "140px", background: "rgb(232, 240, 254)" }}
        options={countryOption}
        onChange={(value) => setFormData({ ...formData, phone_code: value })}
        className="country-select"
        showSearch
        filterOption={filterOption}
      />
      <Input
        placeholder="Enter your mobile number"
        type="number"
        className="phone-input"
        value={formData.phone}
        style={{ fontFamily: "Graphie-regular" }}
        onChange={(e) =>
          setFormData({ ...formData, phone: e.target.value })
        }
      />
    </div>
  );
};

export default RegisterNumberInput;
