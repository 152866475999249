import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import { Drawer, Dropdown, Input, Space, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Company,
  EditIcon,
  LockIcon,
  MailIcon,
  PencilIcon,
  Profile,
} from "../../utils/Icons";
import ChangePasswordModal from "./child/ChangePasswordModal";
import NameChangeModal from "./child/NameChangeModal";
import getCountrySVG from "react-country-svg-flag";
import ChangeNumberModal from "./child/ChangeNumberModal";
import MobileVerification from "../PortalCredentials/child/MobileVerification";
import { countryCode } from "../../utils/country";
import { Edit, Trash, UploadCloud } from "react-feather";
import { fetcher, getFirstLettersOfName } from "../../utils/helper";
import { Loader } from "../common/Loader";
import { toast } from "react-toastify";
import { getLoginUserDetails } from "../../utils/tools";

const ProfileDrawer = ({ open, setOpen }) => {
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [openNameModal, setOpenNameModal] = useState(false);
  const [openNumberModal, setOpenNumberModal] = useState(false);
  const [phoneCode, setPhoneCode] = useState("+971");
  const [userName, setUserName] = useState("");
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [verify, setVerify] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(
    sessionStorage.getItem("phoneNumber") || ""
  );
  const [mobileFormData, setMobileFormData] = useState({
    phoneCode: "+971",
    phoneNumber: 0,
  });
  const [svgCountry, setSvgCountry] = useState("ae");
  const [loading, setLoading] = useState(false);
  const countryList = countryCode;

  // change width of drawer based on screen...

  const isLargeScreen = useMediaQuery({ query: "(min-width: 1200px)" });
  const isMediumScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const isSmallScreen = useMediaQuery({ query: "(max-width: 767px)" });

  let DrawerWidth = "520px";
  if (isLargeScreen) {
    DrawerWidth = "540px";
  } else if (isMediumScreen) {
    DrawerWidth = "540px";
  } else if (isSmallScreen) {
    DrawerWidth = "100%";
  }

  useEffect(() => {
    if (sessionStorage.getItem("phone_code") != "") {
      setPhoneCode(sessionStorage.getItem("phone_code"));
      setMobileFormData({
        phoneCode: sessionStorage.getItem("phone_code"),
        phoneNumber: sessionStorage.getItem("phoneNumber"),
      });
      setPhoneNumber(sessionStorage.getItem("phoneNumber"));
      countryList.forEach((item) => {
        // console.log(item);
        if (item.countryCodes.includes(sessionStorage.getItem("phone_code"))) {
          setSvgCountry(item.country.toLowerCase());
        }
      });
    } else if (
      sessionStorage.getItem("phoneNumber") != "" &&
      sessionStorage.getItem("phone_code") == ""
    ) {
      // console.log('vvvsv', sessionStorage.getItem('phoneNumber'));
      setMobileFormData({
        phoneCode: "+971",
        phoneNumber: sessionStorage.getItem("phoneNumber").replace("+", ""),
      });
    }
  }, [sessionStorage.getItem("phone_code")]);

  const handleChange = (e) => {
    let file = e.target.files[0];
    let reader = new FileReader();
    if (file) {
      reader.onload = (function (theFile) {
        return async function (event) {
          setLoading(true);
          try {
            const response = await fetcher(
              "governify/customer/profilePicUpdate",
              "POST",
              JSON.stringify({
                profile_pic_name: e.target.files[0].name,
                profile_pic: event.target.result,
              })
            );
            // setLoading(false);
            if (response.status) {
              toast.success(response.message);
              await await getLoginUserDetails();
            } else {
              toast.error(
                "Something went wrong while uploading profile picture"
              );
            }
          } catch (error) {
            console.log("error", error);
          } finally {
            setTimeout(() => {
              setLoading(false);
            }, 5000);
          }
        };
      })(file);
      reader.readAsDataURL(file);
    }
  };

  const removeProfilePicture = async () => {
    setLoading(true);
    try {
      const res = await fetcher(
        "governify/customer/profilePicUpdate",
        "DELETE"
      );
      if (res.status) {
        toast.success(res.message);
        sessionStorage.removeItem("profileImage");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Something went wrong");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  };

  const items = sessionStorage.getItem("profileImage")
    ? [
        {
          label: (
            <div
              className={`d-flex align-items-center gap-2 text-decoration-none p-1 fs-s`}
            >
              <form>
                <label for="profile-file-upload" class="profile-upload-btn">
                  <Edit width="16px" height="20px" />
                  <span
                    className="fs-6 ff-ws"
                    style={{
                      color: "#454545",
                    }}
                  >
                    Change Picture
                  </span>
                </label>
                <input
                  type="file"
                  id="profile-file-upload"
                  class="profile-hidden-input"
                  onChange={(e) => handleChange(e)}
                />
              </form>
            </div>
          ),
          key: "change picture",
        },
        {
          label: (
            <div
              className={`d-flex align-items-center gap-2 text-decoration-none p-1 fs-s`}
              onClick={() => removeProfilePicture()}
            >
              <Trash width="16px" height="20px" color="#EF4444" />
              <span
                className="fs-6 ff-ws"
                style={{
                  color: "#EF4444",
                }}
              >
                Remove Picture
              </span>
            </div>
          ),
          key: "remove picture",
        },
      ]
    : [
        {
          label: (
            <div
              className={`d-flex align-items-center gap-2 text-decoration-none p-1 fs-s`}
            >
              <form>
                <label for="profile-file-upload" class="profile-upload-btn">
                  <UploadCloud width="16px" height="20px" />
                  <span
                    className="fs-6 ff-ws"
                    style={{
                      color: "#454545",
                    }}
                  >
                    Upload Picture
                  </span>
                </label>
                <input
                  type="file"
                  id="profile-file-upload"
                  class="profile-hidden-input"
                  onChange={(e) => handleChange(e)}
                />
              </form>
            </div>
          ),
          key: "change picture",
        },
      ];

  return (
    <>
      {loading && <Loader />}
      <Drawer
        open={open}
        zIndex={9991}
        width={DrawerWidth}
        footer={null}
        closable={false}
        onClose={() => setOpen(false)}
      >
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <span className="fs-24 fw-semibold">My Profile</span>
            <CloseOutlined
              style={{ fontSize: "18px", cursor: "pointer" }}
              onClick={() => setOpen(false)}
            />
          </div>
          <div className="text-nevada fs-16 mt-2">
            Manage all your account details in one place.
          </div>
          <div>
            <div className="mt-4 text-center">
              <div
                className="position-relative"
                style={{ maxWidth: "fit-content", margin: "auto" }}
              >
                {sessionStorage.getItem("profileImage") ? (
                  <img
                    src={sessionStorage.getItem("profileImage")}
                    alt="profile_picture"
                    width="120px"
                    height="120px"
                    style={{ borderRadius: "50%", position: "relative" }}
                  />
                ) : (
                  <div
                    style={{
                      width: "120px",
                      height: "120px",
                      background: "#00BF631F",
                      borderRadius: "50%",
                      position: "relative",
                      display: "inline-block",
                    }}
                  >
                    <span
                      style={{
                        color: "#50C060",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "48px",
                      }}
                      className="position-absolute"
                    >
                      {getFirstLettersOfName(
                        sessionStorage.getItem("userName")
                      )}
                    </span>
                  </div>
                )}
                <Dropdown
                  menu={{ items }}
                  trigger={["hover"]}
                  placement="bottomRight"
                  className=""
                >
                  <span
                    style={{
                      background: "#50C060",
                      padding: "6px 10px 10px",
                      borderRadius: "50%",
                      position: "absolute",
                      right: "0",
                      bottom: "-10px",
                    }}
                  >
                    <Space>
                      <PencilIcon />
                    </Space>
                  </span>
                </Dropdown>
              </div>
              <div></div>
            </div>
            <div className="mt-4">
              <div className="fs-16" style={{ color: "#202223" }}>
                Full Name
              </div>
              <div
                style={{
                  border: "1px solid #8C9196",
                  borderRadius: "8px",
                  padding: "8px 12px",
                  lineHeight: "1",
                }}
                className="mt-2 d-flex align-items-center"
              >
                <Profile width="24px" height="22px" />{" "}
                <span
                  style={{ marginLeft: "5px", marginTop: "5px" }}
                  className="fs-16"
                >
                  {userName}
                </span>{" "}
                <div
                  style={{
                    background: "#EEEEEE",
                    borderRadius: "8px",
                    padding: "7px 9px 9px",
                    cursor: "pointer",
                    marginLeft: "auto",
                  }}
                  onClick={() => setOpenNameModal(true)}
                >
                  <EditIcon />
                </div>
              </div>
              {/* <Input
                style={{
                  border: "1px solid #8C9196",
                  borderRadius: "8px",
                  padding: "8px 12px",
                  lineHeight: "1",
                  fontFamily: "Graphie-Regular",
                }}
                className="mt-2"
                prefix={<Profile width="24px" height="22px" />}
                value={userName}
                suffix={
                  <div
                    style={{
                      background: "#EEEEEE",
                      borderRadius: "8px",
                      padding: "8px",
                      cursor: "pointer",
                      marginLeft: "auto",
                    }}
                    onClick={() => setOpenNameModal(true)}
                  >
                    <EditIcon />
                  </div>
                }
              /> */}
            </div>
            <div className="mt-4">
              <div className="fs-16" style={{ color: "#202223" }}>
                Mobile Number
              </div>
              <div
                style={{
                  border: "1px solid #8C9196",
                  borderRadius: "8px",
                  padding: "8px 12px",
                  lineHeight: "1",
                }}
                className="mt-2 d-flex align-items-center justify-content-between"
              >
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "5px" }}
                >
                  <div>{phoneCode}</div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getCountrySVG(svgCountry),
                    }}
                    style={{
                      width: "24px",
                      height: "15px",
                      display: "block",
                      marginLeft: "5px",
                      marginBottom: "5px",
                    }}
                  ></div>
                  <DownOutlined style={{ fontSize: "14px" }} />
                  <div>{phoneNumber}</div>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "5px" }}
                >
                  <div>
                    {sessionStorage.getItem("mobileVerified") == "0" ? (
                      <button
                        style={{
                          background: "#00BF63",
                          border: "none",
                          borderRadius: "8px",
                          padding: "8px",
                          color: "#fff",
                        }}
                        onClick={() => {
                          setOpenOtpModal(true);
                          setVerify(true);
                        }}
                      >
                        Verify
                      </button>
                    ) : (
                      <span className="fs-s" style={{ color: "#00BF63" }}>
                        Verified
                      </span>
                    )}
                  </div>
                  <div
                    style={{
                      background: "#EEEEEE",
                      borderRadius: "8px",
                      padding: "7px 9px 9px",
                      cursor: "pointer",
                      marginLeft: "auto",
                    }}
                    onClick={() => {
                      setOpenNumberModal(true);
                    }}
                  >
                    <EditIcon />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="fs-16" style={{ color: "#202223" }}>
                Company Name
              </div>
              <Input
                type="text"
                prefix={<Company width="24px" height="24px" />}
                value={sessionStorage.getItem("company")}
                style={{
                  border: "1px solid #D2D5D8",
                  borderRadius: "8px",
                  padding: "12px",
                  lineHeight: "1",
                  background: "#FAFBFB",
                  color: "#8C9196",
                  fontFamily: "Graphie-Regular",
                }}
                className="mt-2"
                disabled
              />
            </div>
            <div className="mt-4">
              <div className="fs-16" style={{ color: "#202223" }}>
                Email address
              </div>
              <Input
                type="text"
                prefix={<MailIcon width="24px" height="24px" />}
                value={sessionStorage.getItem("userEmail")}
                style={{
                  border: "1px solid #D2D5D8",
                  borderRadius: "8px",
                  padding: "12px",
                  lineHeight: "1",
                  background: "#FAFBFB",
                  color: "#8C9196",
                  fontFamily: "Graphie-Regular",
                }}
                className="mt-2"
                disabled
              />
            </div>
            <div className="mt-4">
              <div className="fs-16" style={{ color: "#202223" }}>
                Password
              </div>
              <div
                style={{
                  border: "1px solid #8C9196",
                  borderRadius: "8px",
                  padding: "8px 12px",
                  lineHeight: "1",
                }}
                className="mt-2 d-flex align-items-center"
              >
                <LockIcon />{" "}
                <span
                  style={{ marginLeft: "5px", marginTop: "5px" }}
                  className="fs-16"
                >
                  *************
                </span>{" "}
                <div
                  style={{
                    background: "#EEEEEE",
                    borderRadius: "8px",
                    padding: "7px 9px 9px",
                    cursor: "pointer",
                    marginLeft: "auto",
                  }}
                  onClick={() => setOpenPasswordModal(true)}
                >
                  <EditIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <NameChangeModal
        open={openNameModal}
        setOpen={setOpenNameModal}
        setUserName={setUserName}
      />
      <ChangePasswordModal
        open={openPasswordModal}
        setOpen={setOpenPasswordModal}
      />
      <ChangeNumberModal
        open={openNumberModal}
        setOpen={setOpenNumberModal}
        number={mobileFormData}
        setNumber={setMobileFormData}
        setOpenOtpModal={setOpenOtpModal}
        setVerify={setVerify}
      />
      <MobileVerification
        setOpenOtpModal={setOpenOtpModal}
        open={openOtpModal}
        verifying={verify}
        setVerify={setVerify}
        number={mobileFormData}
      />
    </>
  );
};

export default ProfileDrawer;
