import {
  FileTextOutlined,
  MenuOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Dropdown, Typography } from "antd";
import React from "react";
import {
  CheckListIcon,
  Credentials,
  Logout,
  MyProfileNavIcon,
  Profile,
  TeamMemberNavIcon,
} from "../../utils/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import { getFirstLettersOfName } from "../../utils/helper";

const CustomerMenu = ({
  logoutFunction,
  setShowCredentials,
  setOpenProfileDrawer,
  user,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const items = [
    {
      key: "account",
      type: "group",
      label: "Account",
      children: [
        {
          label: (
            <div
              className={`d-flex align-items-center gap-2 text-decoration-none p-1 fs-s`}
              onClick={() => setOpenProfileDrawer(true)}
            >
              <Profile width="16px" height="20px" />
              <span
                className="fs-6 ff-ws"
                style={{
                  color: "#454545",
                }}
              >
                My Profile
              </span>
            </div>
          ),
          key: "profile",
        },
        ...(sessionStorage.getItem("governifyRole") === "2"
          ? [
              {
                label: (
                  <div
                    className={`d-flex align-items-center gap-2 text-decoration-none p-1 fs-s`}
                    onClick={() => {
                      if (location.pathname === "/team") {
                        return;
                      } else {
                        navigate("/team");
                      }
                    }}
                  >
                    <TeamMemberNavIcon
                      pathname={location.pathname}
                      fill={
                        location.pathname === "/team" ? "#59C080" : "#454545"
                      }
                      width="20px"
                      height="20px"
                    />
                    <span
                      className="fs-6 ff-ws"
                      style={{
                        color:
                          location.pathname === "/team" ? "#59C080" : "#454545",
                      }}
                    >
                      Team Management
                    </span>
                  </div>
                ),
                key: "team-management",
              },
            ]
          : []),
      ],
    },
    { type: "divider" },
    {
      key: "navigation",
      type: "group",
      label: "Navigation",
      children: [
        {
          label: (
            <a
              href="/"
              className={`d-flex align-items-center gap-2 text-decoration-none p-1 fs-s`}
            >
              <PlusOutlined
                className={`fs-18`}
                style={{
                  color: location.pathname === "/" ? "#59C080" : "#454545",
                }}
              />
              <span
                className="fs-6 ff-ws"
                style={{
                  color: location.pathname === "/" ? "#59C080" : "#454545",
                }}
              >
                Service Request
              </span>
            </a>
          ),
          key: "home",
        },
        {
          label: (
            <a
              href="/track-request"
              className={`d-flex align-items-center gap-2 text-decoration-none p-1 fs-s`}
            >
              <CheckListIcon
                fill={
                  location.pathname === "/track-request" ? "#59C080" : "#454545"
                }
              />
              <span
                className="fs-6 ff-ws"
                style={{
                  color:
                    location.pathname === "/track-request"
                      ? "#59C080"
                      : "#454545",
                }}
              >
                Request tracking
              </span>
            </a>
          ),
          key: "track-request",
        },
        {
          label: (
            <a
              href="/report"
              className={`d-flex align-items-center gap-2 text-decoration-none p-1 fs-s`}
            >
              <FileTextOutlined
                className={`fs-18`}
                style={{
                  color:
                    location.pathname === "/report" ? "#59C080" : "#454545",
                }}
              />
              <span
                className="fs-6 ff-ws"
                style={{
                  color:
                    location.pathname === "/report" ? "#59C080" : "#454545",
                }}
              >
                Insights and Reports
              </span>
            </a>
          ),
          key: "report",
        },
      ],
    },
    {
      type: "divider",
    },
    {
      key: "setting",
      type: "group",
      label: "Settings",
      children: [
        {
          label: (
            <div
              className={`d-flex align-items-center gap-2 text-decoration-none p-1 fs-s`}
              onClick={() => {
                if (location.pathname === "/portals") {
                  return;
                } else {
                  navigate("/portals");
                }
              }}
            >
              <Credentials
                fill={location.pathname === "/portals" ? "#59C080" : "#454545"}
                width="20px"
                height="20px"
              />
              <span
                className="fs-6 ff-ws"
                style={{
                  color:
                    location.pathname === "/portals" ? "#59C080" : "#454545",
                }}
              >
                Credentials Management
              </span>
            </div>
          ),
          key: "portal-credentials",
        },
        {
          label: (
            <div
              className={`d-flex align-items-center gap-2 text-decoration-none p-1 fs-s`}
              onClick={() => logoutFunction()}
            >
              <Logout />
              <span className="fs-6 ff-ws" style={{ color: "#EF4444" }}>
                Logout
              </span>
            </div>
          ),
          key: "logout",
        },
      ],
    },
  ];

  return (
    <>
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      placement="bottomRight"
      className="d-none d-md-flex d-lg-flex d-xl-flex d-xxl-flex"
    >
      <Typography
        className="d-none d-md-flex d-lg-flex d-xl-flex d-xxl-flex"
        style={{
          gap: "15px",
          alignItems: "center",
          background: "#ffffff",
          padding: "8px",
          paddingLeft: "15px",
          borderRadius: "10px 50px 50px 10px",
          cursor: 'pointer',
        }}
      >
        {sessionStorage.getItem("role") === "customer" ? (
          <>
            {" "}
            <span>
              <img
                src={
                  sessionStorage.getItem("profileLogo") === null
                    ? "https://governify.tasc360.com/assets/tasc.png"
                    : sessionStorage.getItem("profileLogo")
                }
                width="80px"
                alt="tasc"
                height="auto"
              />
            </span>
            {sessionStorage.getItem("profileImage") ? (
              <img
                src={sessionStorage.getItem("profileImage")}
                alt="profile_picture"
                width="40px"
                height="40px"
                style={{ borderRadius: "50%", position: "relative" }}
              />
            ) : (
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  background: "#00BF631F",
                  borderRadius: "50%",
                  position: "relative",
                  display: "inline-block",
                }}
              >
                <span
                  style={{
                    color: "#50C060",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "1rem",
                    fontWeight: '600'
                  }}
                  className="position-absolute"
                >
                  {getFirstLettersOfName(sessionStorage.getItem("userName"))}
                </span>
              </div>
            )}
          </>
        ) : (
          <>
            <span className="onboardify-welcome">Welcome</span>{" "}
            <span className="onboardify-welcome-text-hani">{user}</span>
          </>
        )}
      </Typography>
    </Dropdown>
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      placement="bottomRight"
      className="d-flex d-md-none d-lg-none d-xl-none d-xxl-none"
    >
      <MenuOutlined
        style={{ color: "#59C080", fontSize: "26px" }}
        onClick={(e) => e.preventDefault()}
        className="d-flex d-md-none d-lg-none d-xl-none d-xxl-none"
      />
    </Dropdown>
    </>
  );
};

export default CustomerMenu;
