import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../common/Footer";
import { fetcher, getBrowserInfo, isUserVerified } from "../../utils/helper";
import { userSettingData } from "../../utils/tools";

const PanelWrapper = () => {
  const [user, setUser] = useState("");
  const token = sessionStorage.getItem("token");
  const role = sessionStorage.getItem("role");
  const [isVerified, setIsVerified] = useState(false);
  const [profileLogo, setProfileLogo] = useState("");
  const location = useLocation();
  const data = JSON.parse(sessionStorage.getItem("settings")) || {
    image:
      "https://onboardify.tasc360.com/uploads/governify/1718271730_1718195689_Products%20Logo%20(1).png",
    site_bg: "#ffffff",
    button_bg: "#5ac063",
    banner_bg: "#5ac063",
    banner_content:
      "Hire an attitude, not just experience and qualification. Greg Savage.",
    header_bg: "#f7f7f7",
    head_title_color: "#5ac063",
    form_description:
      "Please fill out the form to proceed with the needed action to provide you with this service",
  };

  const getLoginUserDetails = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const url = `loginUserDetails/${token}`;
      const method = "GET";
      const response = await fetcher(url, method);
      if (response.success) {
        sessionStorage.setItem("userName", response.data.name);
        sessionStorage.setItem("userEmail", response.data.email);
        sessionStorage.setItem("userId", response.data.user_id);
        sessionStorage.setItem("phoneNumber", response.data.phone);
        sessionStorage.setItem(
          "mobileVerified",
          response.data.mobile_verification_status
        );
        sessionStorage.setItem("phone_code", response.data.phone_code);
        sessionStorage.setItem("createdAt", response.data.created_at);
        sessionStorage.setItem("company", response.data.company);
        sessionStorage.setItem("governifyRole", response.data.governify_role);
        sessionStorage.setItem(
          "profileImage",
          response.data.governify_user_image
        );
        setUser(response.data.name);
      }
    } catch (err) {
      console.log(err, "error");
    } finally {
    }
  };

  const getProfileData = async () => {
    try {
      const response = await fetcher(
        `governifyprofile/admin/getProfileByUserId/${sessionStorage.getItem(
          "userId"
        )}`,
        "GET"
      );
      if (response.status) {
        let logo = null;
        if (
          response.response.logo_location !== undefined &&
          response.response.logo_location.length > 0
        ) {
          logo = response.response.logo_location;
          setProfileLogo(response.response.logo_location);
        }
        sessionStorage.setItem("profileLogo", logo);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  useEffect(() => {
    getLoginUserDetails();
  }, [token]);

  const getSiteSetting = async () => {
    await userSettingData();
  };

  useEffect(() => {
    getSiteSetting();
  }, []);

  let browserInfo = getBrowserInfo();

  let verified = isUserVerified(
    sessionStorage.getItem("userId"),
    browserInfo.browser,
    browserInfo.os
  );
  // console.log('verified', verified);
  useEffect(() => {
    if (verified) {
      setIsVerified(verified);
    }
  }, [verified]);

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <>
      <Header user={user} />
      <div
        className="container d-flex flex-column h-100 text-center"
        style={{
          background:
            location.pathname === "/report" ? "#F6F6FB" : data.site_bg,
        }}
      >
        <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
          <Outlet />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PanelWrapper;
