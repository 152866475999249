import { Input, Modal } from "antd";
import React, { useState } from "react";
import { LockIcon } from "../../../utils/Icons";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { fetcher } from "../../../utils/helper";
import { toast, ToastContainer } from "react-toastify";
import { Loader } from "../../common/Loader";

const ChangePasswordModal = ({open, setOpen}) => {
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [errorObj, setErrorObj] = useState({
    current_pass: false,
    new_pass: false,
    confirm_pass: false,
  });
  const [conPassMsg, setConPassMsg] = useState('Please complete this field.');

  const validatePasswordForm = () => {
    let errObj = {
      current: false,
      new: false,
      confirm: false,
    }
    let valid = true;
    if(formData.currentPassword === '') {
      errObj.current = true;
      valid = false;
    }
    if(formData.newPassword === '') {
      errObj.new = true;
      valid = false;
    }
    if(formData.confirmPassword === '') {
      errObj.confirm = true;
      setConPassMsg('This field is required.');
      valid = false;
    } else if(formData.confirmPassword !== formData.newPassword) {
      errObj.confirm = true;
      setConPassMsg('Confirm password should be same as new password.');
      valid = false;
    }

    setErrorObj({current_pass: errObj.current, new_pass: errObj.new, confirm_pass: errObj.confirm});

    return { valid };
  }

  const handleSubmit = async () => {
    let { valid } = validatePasswordForm();
    if(!valid) {
      return;
    }
    let url = 'governify/customer/updatePassword';
    let method = 'POST';
    let payload = JSON.stringify({
      current_password: formData.currentPassword,
      new_password: formData.newPassword,
    });
    setLoading(true);
    try {
      const res = await fetcher(url, method, payload);
      if(res.status) {
        toast.success(res.message);
        setTimeout(() => {
          setLoading(false);
          setOpen(false);
        },2000)
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error('Something went wrong');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
    {loading && <Loader />}
      <Modal
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
        zIndex={9992}
        centered
      >
        <div>
          <div className="fs-24 fw-bold text-center">Change Password</div>
          <div
            style={{ marginTop: "6px", color: "#6D7175" }}
            className="fs-16 text-center"
          >
            Secure your account by updating your current password.
          </div>
          <div
            style={{ marginTop: "24px", gap: "8px" }}
            className="d-flex flex-column"
          >
            <div className="fs-16" style={{ color: "#202223" }}>
              Enter current password
            </div>
            <Input
              type={showPassword.current ? "text" : "password"}
              prefix={<LockIcon />}
              onChange={(e) =>
                setFormData({ ...formData, currentPassword: e.target.value })
              }
              placeholder="Enter current password"
              value={formData.currentPassword}
              suffix={
                showPassword.current ? (
                  <EyeInvisibleOutlined
                    onClick={() =>
                      setShowPassword({ ...showPassword, current: false })
                    }
                    style={{fontSize: '24px'}}
                  />
                ) : (
                  <EyeOutlined
                    onClick={() =>
                      setShowPassword({ ...showPassword, current: true })
                    }
                    style={{fontSize: '24px'}}
                  />
                )
              }
              className="profile-input"
            />
            {errorObj.current_pass && (
                <span style={{ color: "#FD5749" }}>
                  This field is required.
                </span>
              )}
          </div>
          <div
            style={{ marginTop: "24px", gap: "8px" }}
            className="d-flex flex-column"
          >
            <div className="fs-16" style={{ color: "#202223" }}>
              Enter new password
            </div>
            <Input
              type={showPassword.new ? "text" : "password"}
              prefix={<LockIcon />}
              onChange={(e) =>
                setFormData({ ...formData, newPassword: e.target.value })
              }
              placeholder="Enter new password"
              value={formData.newPassword}
              suffix={
                showPassword.new ? (
                  <EyeInvisibleOutlined
                    onClick={() =>
                      setShowPassword({ ...showPassword, new: false })
                    }
                    style={{fontSize: '24px'}}
                  />
                ) : (
                  <EyeOutlined
                    onClick={() =>
                      setShowPassword({ ...showPassword, new: true })
                    }
                    style={{fontSize: '24px'}}
                  />
                )
              }
              className="profile-input"
            />
            {errorObj.new_pass && (
                <span style={{ color: "#FD5749" }}>
                  This field is required.
                </span>
              )}
          </div>
          <div
            style={{ marginTop: "24px", gap: "8px" }}
            className="d-flex flex-column"
          >
            <div className="fs-16" style={{ color: "#202223" }}>
              Confirm new password
            </div>
            <Input
              // style={{padding: '8px'}}
              type={showPassword.confirm ? "text" : "password"}
              prefix={<LockIcon />}
              onChange={(e) =>
                setFormData({ ...formData, confirmPassword: e.target.value })
              }
              placeholder="Confirm new password"
              value={formData.confirmPassword}
              suffix={
                showPassword.confirm ? (
                  <EyeInvisibleOutlined
                    onClick={() =>
                      setShowPassword({ ...showPassword, confirm: false })
                    }
                    style={{fontSize: '24px'}}
                  />
                ) : (
                  <EyeOutlined
                    onClick={() =>
                      setShowPassword({ ...showPassword, confirm: true })
                    }
                    style={{fontSize: '24px'}}
                  />
                )
              }
              className="profile-input"
            />
            {errorObj.confirm_pass && (
                <span style={{ color: "#FD5749" }}>
                  {conPassMsg}
                </span>
              )}
          </div>
          <div style={{ marginTop: "24px" }}>
            <button
              style={{
                background: "#00BF63",
                color: "#fff",
                width: "100%",
                padding: "13px",
                border: "none",
                fontWeight: "600",
                borderRadius: "8px"
              }}
              onClick={() => handleSubmit()}
            >
              Change password
            </button>
          </div>
        </div>
      </Modal>
      {/* <ToastContainer position="bottom-right" className='custom-toast' /> */}
    </>
  );
};

export default ChangePasswordModal;
