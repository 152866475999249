import { Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { countryCode } from "../../../utils/country";
import getCountrySVG from "react-country-svg-flag";

const ChangeNumberModal = ({ open, setOpen, number, setNumber, setOpenOtpModal, setVerify }) => {
  const countryCodes = countryCode;
  const [countryOption, setCountryOption] = useState([]);
  // const [phoneCode, setPhoneCode] = useState("+971");
  // const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (countryCodes.length && countryOption.length === 0) {
      let option = [];
      countryCodes.map((item) => {
        let countrySvg = getCountrySVG(item.country.toLowerCase());
        option.push({
          label: (
            <div className="countryOption ff-ws" style={{ gap: "5px" }}>
              {item.countryCodes[0] + " "}
              <span
                dangerouslySetInnerHTML={{
                  __html: countrySvg,
                }}
                className="tw-max-w-[20px] tw-h-[20px]"
                style={{ marginLeft: "auto" }}
              ></span>
            </div>
          ),
          value: item.countryCodes[0],
        });
      });
      setCountryOption(option);
    }
  }, []);

  const filterOption = (input, option) => {
    return (
      option.value.includes(input)
    );
  };

  return (
    <>
      <Modal
        open={open}
        footer={null}
        zIndex={9992}
        centered
        onCancel={() => setOpen(false)}
      >
        <div>
          <div className="fs-24 fw-bold text-center">Change mobile number</div>
          <div
            style={{ marginTop: "6px", color: "#6D7175" }}
            className="fs-16 text-center"
          >
            Update the phone number linked to your account for verification.
          </div>
          <div
            style={{ marginTop: "24px", gap: "8px" }}
            className="d-flex flex-column"
          >
            <div className="fs-16" style={{ color: "#202223" }}>
              Mobile Number
            </div>
            <div
              className="mobile-inp-field"
              style={{
                borderColor: "#C9CCCF",
              }}
            >
              <Select
                value={number.phoneCode}
                style={{ width: "140px" }}
                options={countryOption}
                onChange={(value) => setNumber({ ...number, phoneCode: value })}
                className="country-select"
                showSearch
                filterOption={filterOption}
              />
              <Input
                placeholder="Enter your mobile number"
                type="number"
                className="phone-input"
                value={number.phoneNumber}
                style={{ fontFamily: "Graphie-regular" }}
                onChange={(e) =>
                  setNumber({ ...number, phoneNumber: e.target.value })
                }
              />
            </div>
          </div>
          <div style={{ marginTop: "24px" }}>
            <button
              style={{
                background: "#00BF63",
                color: "#fff",
                width: "100%",
                padding: "13px",
                border: "none",
                fontWeight: "600",
                borderRadius: "8px",
              }}
              onClick={() => {
                setOpen(false);
                setOpenOtpModal(true);
                setVerify(true);
              }}
            >
              Update
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ChangeNumberModal;
