import { Button, Checkbox } from "antd";
import Hero from "../common/Hero";
import { TeamMemberIcon } from "../../assets/image";
import { DownOutlined, SettingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { fetcher } from "../../utils/helper";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { DrawerToCreateAccessProfile } from "./DrawerToCreateAccessProfile";
import { DrawerToEditAccessProfile } from "./DrawerToEditAccessProfile";
import { ManageAccessProfileTable } from "./ManageAccessProfileTable";
import { ManageAccessTeamTable } from "./ManageAccessTeamTable";
import { DeleteProfileModal } from "./DeleteProfileModal";
import { DrawerToInviteUserForAccessProfile } from "./DrawerToInviteUserForAccessProfile";
import { DeleteTeamMemberModal } from "./DeleteTeamMemberModal";

export const TeamManagement = () => {
  const [defaultSelectedButton, SetDefaultSelectedButton] = useState("team");
  const [openDrawerInviteUser, setOpenDrawerInviteUser] = useState(false);
  const [openDrawerProfile, setOpenDrawerProfile] = useState(false);
  const [openEditDrawerProfile, setOpenEditDrawerProfile] = useState(false);
  const [openDeleteProfileModal, setOpenDeleteProfileModal] = useState(false);
  const [openDeleteInviteMemberModal, setOpenDeleteInviteMemberModal] =
    useState(false);
  const [inviteUserData, setInviteUserData] = useState({
    email: "",
    role: "",
    profile: "",
  });
  const [createProfileData, setCreateProfileData] = useState({
    title: "",
    description: "",
    categoryServices: {},
  });
  const [updateProfileData, setUpdateProfileData] = useState({
    title: "",
    description: "",
    categoryServices: {},
  });
  const [profileToDelete, setProfileToDelete] = useState({});
  const [teamMemberToDelete, setTeamMemberToDelete] = useState({});
  const [dataSourceAccessProfile, setDataSourceAccessProfile] = useState([]);
  const [dataSourceUserTeam, setDataSourceUserTeam] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [currentAdminProfile, setCurrentAdminProfile] = useState("");
  const [activeKeys, setActiveKeys] = useState([]);

  const togglePanel = (key) => {
    setActiveKeys(
      (prevKeys) =>
        prevKeys.includes(key)
          ? prevKeys.filter((k) => k !== key) // Remove key if already active
          : [...prevKeys, key] // Add key if not active
    );
  };

  const handleChangeCategory = (e, item) => {
    let tempSelectedServices = [];
    categoryData.forEach((detail) => {
      if (detail.id === item.id) {
        detail.gov_service_requests.forEach((subDetail) => {
          tempSelectedServices.push(subDetail.id);
        });
      }
    });

    let tempProfileData = { ...createProfileData };
    let tempCategory = { ...createProfileData.categoryServices };

    if (e.target.checked) {
      tempCategory[item.id] = tempSelectedServices;
    } else {
      tempCategory[item.id] = [];
    }
    tempProfileData.categoryServices = tempCategory;
    setCreateProfileData(tempProfileData);
  };

  const handleDeleteTeamMember = async (record) => {
    if(record.id == sessionStorage.getItem('userId')) {
      toast.warning('You can not delete yourself.');
      return;
    }
    const payload = JSON.stringify({
      user_email: teamMemberToDelete.email,
      gov_manage_access_profile: teamMemberToDelete.accessProfile,
    });

    try {
      const response = await fetcher(
        `governifyprofile/admin/deleteUserFromGovTeamMembersList/${sessionStorage.getItem(
          "userId"
        )}`,
        "POST",
        payload
      );
      if (response.status) {
        toast.success(response.message);
      }
    } catch (err) {
      console.log(err, "error");
    } finally {
      setOpenDeleteInviteMemberModal(false);
      setTeamMemberToDelete({});
      gtAccessProfileTeamData();
    }
  };

  const handleChangeCategoryServices = (e, item, subItem) => {
    let tempProfileData = { ...createProfileData };
    let tempCategory = { ...createProfileData.categoryServices };

    if (e.target.checked) {
      tempCategory[item.id].push(subItem.id);
    } else {
      const updatedArray = tempCategory[item.id].filter(
        (service) => service !== subItem.id
      );
      tempCategory[item.id] = updatedArray;
    }
    tempProfileData.categoryServices = tempCategory;
    setCreateProfileData(tempProfileData);
  };

  const getItems = () => {
    let tempData = [];
    categoryData.forEach((item, index) => {
      tempData.push({
        key: index.toString(),
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <Checkbox
              onChange={(e) => handleChangeCategory(e, item)}
              checked={createProfileData.categoryServices[item.id].length > 0}
            />
            <p style={{ marginBottom: "0px" }}>{item.title}</p>
          </div>
        ),
        children: (
          <div style={{ marginLeft: "60px" }}>
            {item.gov_service_requests.map((subItem) => (
              <div
                key={subItem.title}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  gap: "5px",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Checkbox
                  onChange={(e) =>
                    handleChangeCategoryServices(e, item, subItem)
                  }
                  checked={createProfileData.categoryServices[item.id].includes(
                    subItem.id
                  )}
                />
                <p style={{ marginBottom: "0px" }}>{subItem.title}</p>
              </div>
            ))}
          </div>
        ),
        extra: (
          <span
            onClick={(e) => {
              e.stopPropagation(); // Prevent default panel toggle
              togglePanel(index.toString());
            }}
            style={{ cursor: "pointer" }}
          >
            <DownOutlined />
          </span>
        ),
        style: {
          // marginBottom: 24,
          background: "white",
          border: "none",
        },
      });
    });

    return tempData;
  };

  const handleChangeEditCategory = (e, item) => {
    let tempProfileData = { ...updateProfileData };
    let tempCategory = { ...updateProfileData.categoryServices };

    let tempSelectedServices = [];
    categoryData.forEach((detail) => {
      if (detail.id === item.id) {
        detail.gov_service_requests.forEach((subDetail) => {
          tempSelectedServices.push(subDetail.id);
        });
      }
    });

    if (e.target.checked) {
      tempCategory[item.id] = tempSelectedServices;
    } else {
      tempCategory[item.id] = [];
    }
    tempProfileData.categoryServices = tempCategory;
    setUpdateProfileData(tempProfileData);
  };

  const handleChangeEditCategoryServices = (e, item, subItem) => {
    let tempProfileData = { ...updateProfileData };
    let tempCategory = { ...updateProfileData.categoryServices };

    if (e.target.checked) {
      tempCategory[item.id].push(subItem.id);
    } else {
      const updatedArray = tempCategory[item.id].filter(
        (service) => service !== subItem.id
      );
      tempCategory[item.id] = updatedArray;
    }
    tempProfileData.categoryServices = tempCategory;
    setUpdateProfileData(tempProfileData);
  };

  const getCategoryItemsEditProfile = () => {
    let tempData = [];
    categoryData.forEach((item, index) => {
      tempData.push({
        key: index.toString(),
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <Checkbox
              onChange={(e) => handleChangeEditCategory(e, item)}
              checked={updateProfileData.categoryServices[item.id].length > 0}
            />
            <p style={{ marginBottom: "0px" }}>{item.title}</p>
          </div>
        ),
        children: (
          <div style={{ marginLeft: "60px" }}>
            {item.gov_service_requests.map((subItem) => (
              <div
                key={subItem.title}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  gap: "5px",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Checkbox
                  onChange={(e) =>
                    handleChangeEditCategoryServices(e, item, subItem)
                  }
                  checked={updateProfileData.categoryServices[item.id].includes(
                    subItem.id
                  )}
                />
                <p style={{ marginBottom: "0px" }}>{subItem.title}</p>
              </div>
            ))}
          </div>
        ),
        extra: (
          <span
            onClick={(e) => {
              e.stopPropagation(); // Prevent default panel toggle
              togglePanel(index.toString());
            }}
            style={{ cursor: "pointer" }}
          >
            <DownOutlined />
          </span>
        ),
        style: {
          // marginBottom: 24,
          background: "white",
          border: "none",
        },
      });
    });

    return tempData;
  };

  const handleTeamMemberScreen = () => {
    SetDefaultSelectedButton("team");
  };

  const handleProfileScreen = () => {
    SetDefaultSelectedButton("profile");
  };

  const handleInviteUser = async () => {
    const payload = JSON.stringify({
      email: inviteUserData.email,
      access_profile_id: inviteUserData.role === '2' ? currentAdminProfile.id:inviteUserData.profile,
      role: inviteUserData.role,
      country_code: 'KSA'
    });

    try {
      const response = await fetcher(
        `governifyprofile/admin/inviteGovernifyUser/${sessionStorage.getItem(
          "userId"
        )}`,
        "POST",
        payload
      );

      if (response.success) {
        toast.success(response.message);
        setOpenDrawerInviteUser(false);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  const handleChangeInviteUserEmail = (e) => {
    setInviteUserData({ ...inviteUserData, email: e.target.value });
  };

  const handleChangeInviteUserRole = (e) => {
    if(e === '2'){
      setInviteUserData({ ...inviteUserData, role: e , profile:''});
    }else{
      setInviteUserData({ ...inviteUserData, role: e});
    }
  };

  const handleChangeInviteUserProfile = (e) => {
    setInviteUserData({ ...inviteUserData, profile: e });
  };

  const handleChangeProfileName = (e) => {
    setCreateProfileData({ ...createProfileData, title: e.target.value });
  };

  const handleChangeProfileDescription = (e) => {
    setCreateProfileData({ ...createProfileData, description: e.target.value });
  };

  const handleDeleteTeamMemberModal = (record) => {
    setTeamMemberToDelete(record);
    setOpenDeleteInviteMemberModal(true);
  };

  const handleEditDrawerAccessProfile = (record) => {
    setUpdateProfileData(record);
    setOpenEditDrawerProfile(true);
  };

  const handleDeleteDrawerAccessProfile = (record) => {
    setProfileToDelete(record);
    setOpenDeleteProfileModal(true);
  };

  const getUsersAssignedToAccessProfile = (users) => {
    if (users !== null) {
      let userList = users.split(",");
      return userList.length;
    } else {
      return 0;
    }
  };

  const getAllCategoryListing = async () => {
    try {
      const response = await fetcher(
        `governifyprofile/admin/governifyUserProfileDetails/${sessionStorage.getItem(
          "userId"
        )}`,
        "GET"
      );
      const response1 = await fetcher(
        `governifyprofile/admin/govManageAccessProfileByUserId/${sessionStorage.getItem(
          "userId"
        )}`,
        "GET"
      );

      if (response.status) {
        let tempCategoryServicesObj = {};
        setCurrentAdminProfile(response.response.governify_profile_data);
        setCategoryData(response.response.governify_categories_and_services);
        response.response.governify_categories_and_services.forEach((item) => {
          tempCategoryServicesObj[item.id] = [];
        });

        setCreateProfileData({
          ...createProfileData,
          categoryServices: tempCategoryServicesObj,
        });
      }
      if (response1.status) {
        let tempProfileAccessData = [];
        response1.response.forEach((item) => {
          tempProfileAccessData.push({
            id: item.id,
            title: item.profile_name,
            description: item.profile_description,
            users: getUsersAssignedToAccessProfile(item.users),
            categoryServices: JSON.parse(item.gov_category_service),
          });
        });
        setDataSourceAccessProfile(tempProfileAccessData);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleCreateProfile = async () => {
    let payload = JSON.stringify({
      admin_gov_profile_id: currentAdminProfile.id.toString(),
      profile_name: createProfileData.title,
      profile_description: createProfileData.description,
      gov_category_service: createProfileData.categoryServices,
    });

    try {
      const response = await fetcher(
        `governifyprofile/admin/govManageAccessProfile`,
        "POST",
        payload
      );
      if (response.status) {
        setOpenDrawerProfile(false);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err, "error");
    } finally {
      getAllCategoryListing();
    }
  };

  const handleEditProfile = async () => {
    let payload = JSON.stringify({
      admin_gov_profile_id: currentAdminProfile.id.toString(),
      profile_name: updateProfileData.title,
      profile_description: updateProfileData.description,
      gov_category_service: updateProfileData.categoryServices,
    });

    try {
      const response = await fetcher(
        `governifyprofile/admin/govManageAccessProfile/${updateProfileData.id}`,
        "PUT",
        payload
      );
      if (response.status) {
        setOpenEditDrawerProfile(false);
        toast.success(response.message);
      }
    } catch (err) {
      console.log(err, "error");
    } finally {
      getAllCategoryListing();
    }
  };

  const handleEditProfileName = (e) => {
    setUpdateProfileData({ ...updateProfileData, title: e.target.value });
  };

  const handleEditProfileDescription = (e) => {
    setUpdateProfileData({ ...updateProfileData, description: e.target.value });
  };

  const handleDeleteProfile = async () => {
    try {
      const response = await fetcher(
        `governifyprofile/admin/govManageAccessProfile/${profileToDelete.id}`,
        "DELETE"
      );
      if (response.status) {
        setOpenDeleteProfileModal(false);
        toast.success(response.message);
      }
    } catch (err) {
      console.log(err, "error");
    } finally {
      getAllCategoryListing();
    }
  };

  const getAccessProfileByEmail = (email, profileListing) => {
    let profile = "";
    profileListing.forEach((item) => {
      if (item.users !== null) {
        if (item.users.includes(email)) {
          profile = item.id;
        }
      }
    });

    return profile;
  };

  const checkUserInvitationStatus = (input) => {
    const inputDate = new Date(input); // Parse the input date
    const currentDate = new Date(); // Get the current date and time

    // Calculate the difference in milliseconds and convert to hours
    const hoursDifference = (currentDate - inputDate) / (1000 * 60 * 60);

    return hoursDifference >= 48 ? "Expired" : "Pending";
  };

  const getDateFormat = (tempDate) => {
    const inputDate = new Date(tempDate);
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = inputDate.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  const getUserInvitationDate = (item) => {
    if (item.inviteDetails) {
      return getDateFormat(item.inviteDetails.created_at);
    } else {
      return "--";
    }
  };

  const getInviteId = (item) => {
    if (item.inviteDetails) {
      return item.inviteDetails.inviter_id;
    } else {
      return "";
    }
  };

  const getStatusOfInvitedUser = (item) => {
    if (item.inviteDetails) {
      if (item.inviteDetails.invitation_status === "done") {
        return "Accepted";
      } else {
        return checkUserInvitationStatus(item.inviteDetails.created_at);
      }
    } else {
      return "Active";
    }
  };

  const gtAccessProfileTeamData = async () => {
    try {
      const response = await fetcher(
        `governifyprofile/admin/getGovTeamMembersList/${sessionStorage.getItem(
          "userId"
        )}`,
        "GET"
      );
      if (response.status) {
        let tempUserProfile = [];
        response.response[0].users_details.forEach((item) => {
          tempUserProfile.push({
            id: item.id,
            name: item.name,
            email: item.email,
            role: item.governify_role,
            accessProfile: getAccessProfileByEmail(
              item.email,
              response.response[0].manage_access_profiles
            ),
            invitationDate: getUserInvitationDate(item),
            inviteId: getInviteId(item),
            status: getStatusOfInvitedUser(item),
          });
        });
        setDataSourceUserTeam(tempUserProfile);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleChangeUserRole = async (e, record) => {

    if(record.id == sessionStorage.getItem('userId')) {
      toast.warning('You can not edit your own access');
      return;
    }

    const payload = JSON.stringify({
      user_id: record.id,
      governify_role: e,
    });

    try {
      const response = await fetcher(
        `governifyprofile/admin/updateUserRoleByAdmin/${sessionStorage.getItem(
          "userId"
        )}`,
        "POST",
        payload
      );
      if (response.status) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err, "error");
    } finally {
      gtAccessProfileTeamData();
    }
  };

  const handleChangeAccessProfile = async (e, record) => {
    const payload = JSON.stringify({
      users: record.email,
      gov_manage_access_profile: e,
    });

    try {
      const response = await fetcher(
        `governifyprofile/admin/assignedUserToGovManageAccessProfile/${sessionStorage.getItem(
          "userId"
        )}`,
        "POST",
        payload
      );
      if (response.status) {
        toast.success(response.message);
      }
    } catch (err) {
      console.log(err, "error");
    } finally {
      gtAccessProfileTeamData();
      getAllCategoryListing();
    }
  };

  useEffect(() => {
    getAllCategoryListing();
  }, []);

  useEffect(() => {
    gtAccessProfileTeamData();
  }, []);

  return (
    <div>
      <Hero
        heading={"Team Management"}
        subheading="Stay Informed and in control of the overall status of your requests."
        forHome={true}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          background: "white",
          padding: "16px",
          paddingLeft: "0px",
        }}
      >
        <Button
          onClick={handleTeamMemberScreen}
          icon={
            <TeamMemberIcon defaultSelectedButton={defaultSelectedButton} />
          }
          style={{
            background: defaultSelectedButton === "team" ? "#59C080" : "white",
            color: defaultSelectedButton === "team" ? "white" : "black",
            border: "none",
          }}
        >
          Team Members
        </Button>
        <Button
          icon={
            <SettingOutlined
              style={{
                color: defaultSelectedButton === "profile" ? "white" : "black",
              }}
            />
          }
          onClick={handleProfileScreen}
          style={{
            background:
              defaultSelectedButton === "profile" ? "#59C080" : "white",
            color: defaultSelectedButton === "profile" ? "white" : "black",
            border: "none",
            marginLeft: "10px",
          }}
        >
          Manage Access Profiles
        </Button>
      </div>

      {defaultSelectedButton === "team" && (
        <ManageAccessTeamTable
          setOpenDrawerInviteUser={setOpenDrawerInviteUser}
          dataSourceUserTeam={dataSourceUserTeam}
          dataSourceAccessProfile={dataSourceAccessProfile}
          handleDeleteTeamMember={handleDeleteTeamMember}
          handleChangeUserRole={handleChangeUserRole}
          handleChangeAccessProfile={handleChangeAccessProfile}
          handleDeleteTeamMemberModal={handleDeleteTeamMemberModal}
        />
      )}

      {defaultSelectedButton === "profile" && (
        <ManageAccessProfileTable
          setOpenDrawerProfile={setOpenDrawerProfile}
          dataSourceAccessProfile={dataSourceAccessProfile}
          handleEditDrawerAccessProfile={handleEditDrawerAccessProfile}
          handleDeleteDrawerAccessProfile={handleDeleteDrawerAccessProfile}
        />
      )}

      {openDrawerInviteUser && (
        <DrawerToInviteUserForAccessProfile
          setOpenDrawerInviteUser={setOpenDrawerInviteUser}
          openDrawerInviteUser={openDrawerInviteUser}
          inviteUserData={inviteUserData}
          handleInviteUser={handleInviteUser}
          handleChangeInviteUserEmail={handleChangeInviteUserEmail}
          handleChangeInviteUserRole={handleChangeInviteUserRole}
          handleChangeInviteUserProfile={handleChangeInviteUserProfile}
          dataSourceAccessProfile={dataSourceAccessProfile}
        />
      )}

      {openDrawerProfile && (
        <DrawerToCreateAccessProfile
          openDrawerProfile={openDrawerProfile}
          setOpenDrawerProfile={setOpenDrawerProfile}
          handleCreateProfile={handleCreateProfile}
          createProfileData={createProfileData}
          handleChangeProfileName={handleChangeProfileName}
          handleChangeProfileDescription={handleChangeProfileDescription}
          activeKeys={activeKeys}
          getItems={getItems}
        />
      )}

      {openEditDrawerProfile && (
        <DrawerToEditAccessProfile
          openEditDrawerProfile={openEditDrawerProfile}
          setOpenEditDrawerProfile={setOpenEditDrawerProfile}
          handleEditProfile={handleEditProfile}
          updateProfileData={updateProfileData}
          handleEditProfileName={handleEditProfileName}
          handleEditProfileDescription={handleEditProfileDescription}
          activeKeys={activeKeys}
          getItems={getCategoryItemsEditProfile}
        />
      )}

      {openDeleteProfileModal && (
        <DeleteProfileModal
          profileToDelete={profileToDelete}
          openDeleteProfileModal={openDeleteProfileModal}
          handleDeleteProfile={handleDeleteProfile}
          setOpenDeleteProfileModal={setOpenDeleteProfileModal}
        />
      )}

      {openDeleteInviteMemberModal && (
        <DeleteTeamMemberModal
          teamMemberToDelete={teamMemberToDelete}
          openDeleteInviteMemberModal={openDeleteInviteMemberModal}
          handleDeleteTeamMember={handleDeleteTeamMember}
          setOpenDeleteInviteMemberModal={setOpenDeleteInviteMemberModal}
        />
      )}

      <ToastContainer position="bottom-right" className="custom-toast" />
    </div>
  );
};
