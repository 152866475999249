import { Button, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { fetcher } from "../../utils/helper";
import { ImageUpload } from "./ImageUpload";
import { toast } from "react-toastify";

export const EditProfileModal = ({
  setShowSkeleton,
  setLoading,
  loading,
  setModalOpen,
  profileDataToEdit,
  getListOfAllProfiles,
}) => {
  const [userListing, setUserListing] = useState([]);
  const [allBoardsOptions, setAllBoardsOptions] = useState([]);
  const [allColumnOptions, setAllColumnOptions] = useState([]);
  const [clientNameOptions, setClientNameOptions] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const data = JSON.parse(sessionStorage.getItem("settings"));
  const [clientNameDropDownOptions, setClientNameDropDownOptions] = useState(
    []
  );
  const [profileData, setProfileData] = useState({
    profile_name: profileDataToEdit.title,
    users: profileDataToEdit.users,
    selectedBoard: profileDataToEdit.selectedBoard,
    selectedColumn: profileDataToEdit.selectedColumn,
    selectedColumnValue: profileDataToEdit.selectedColumnValue,
    image: "",
    image_name: "",
    category: profileDataToEdit.category,
    clientName: JSON.parse(profileDataToEdit.clientName).name,
    clientNameColumn: JSON.parse(profileDataToEdit.clientName).column,
  });


  const handleChangeProfileTitle = (event) => {
    setProfileData({ ...profileData, profile_name: event.target.value });
  };

  const handleUserChange = (e) => {
    setProfileData({ ...profileData, users: e });
  };

  const handleEditProfile = async () => {
    setLoading(true);
    try {
      const payload = {
        profile_name: profileData.profile_name,
        users: profileData.users.join(","),
        filter: JSON.stringify({
          selectedBoard: profileData.selectedBoard,
          selectedColumn: profileData.selectedColumn,
          selectedColumnValue: profileData.selectedColumnValue,
        }),
        logo_name:
          profileData.image_name.length === 0 ? "" : profileData.image_name,
        logo: profileData.image.length === 0 ? "" : profileData.image,
        category: profileData.category,
        clientName: JSON.stringify({name:profileData.clientName , column:profileData.clientNameColumn}),
      };

      const response = await fetcher(
        `governifyprofile/superadmin/profileUpdate/${profileDataToEdit.id}`,
        "PUT",
        JSON.stringify(payload)
      );
      if (response.status) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
    } finally {
      setLoading(false);
      setModalOpen(false);
      getListOfAllProfiles();
    }
  };

  const filterOption = (input, option) => {
    return (
      option.label.toLowerCase().includes(input.toLowerCase()) ||
      option.value.toString().toLowerCase().includes(input.toLowerCase())
    );
  };

  const handleFileSelect = (data, imageName) => {
    setProfileData({ ...profileData, image: data, image_name: imageName });
  };

  const handleBoardChange = async (e) => {
    let tempAllClientNameColumnListOptionsDropdownType = [];
    if (e === undefined) {
      setProfileData({
        ...profileData,
        selectedBoard: e,
        selectedColumn: e,
        selectedColumnValue: "",
        clientName: undefined,
        clientNameColumn: undefined,
      });
      return;
    }

    setProfileData({
      ...profileData,
      selectedBoard: e,
      selectedColumn: undefined,
      selectedColumnValue: "",
      clientName: undefined,
      clientNameColumn: undefined,
    });
    setClientNameOptions([]);
    try {
      const response = await fetcher(
        `governify/admin/fetchBoardWiseColumn/${e}`,
        "GET"
      );
      if (response.status) {
        let tempAllColumns = response.response.map((item) => {
          return { label: item.title, value: item.id };
        });

        response.response.map((item) => {
          if (item.type !== null) {
            if (item.type === "dropdown") {
              tempAllClientNameColumnListOptionsDropdownType.push({
                label: item.title,
                value: item.id,
                details: item.settings_str,
              });
            }
          }
        });
        setClientNameDropDownOptions(
          tempAllClientNameColumnListOptionsDropdownType
        );
        setAllColumnOptions(tempAllColumns);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleColumnChange = (e) => {
    setProfileData({ ...profileData, selectedColumn: e });
  };

  const handleChangeFilterValue = (e) => {
    setProfileData({ ...profileData, selectedColumnValue: e.target.value });
  };

  const fetchBoardData = async () => {
    let tempOptionsClientName = [];
    let tempAllClientNameColumnListOptionsDropdownType = [];
    setLoading(true);
    try {
      const response = await fetcher(`governify/admin/fetchAllBoards`, "GET");
      const response1 = await fetcher(
        `governifyprofile/superadmin/getAllUser`,
        "GET"
      );
      const response2 = await fetcher(
        `governify/admin/fetchBoardWiseColumn/${profileDataToEdit.selectedBoard}`,
        "GET"
      );

      if (response.status_code === 200) {
        let tempBoardOptions = response.response.data.boards.map((item) => {
          return {
            id: item.id,
            name: item.name,
            label: item.name,
            value: item.id,
          };
        });
        setAllBoardsOptions(tempBoardOptions);
      }

      if (response1.status) {
        let tempAllUser = response1.response.map((item) => ({
          label: `${item.name} (${item.email}) / ${item.company_name}`,
          value: item.email,
          desc: "",
        }));

        setUserListing(tempAllUser);
      }

      if (response2.status) {
        let tempAllColumns = response2.response.map((item) => {
          return { label: item.title, value: item.id };
        });

        response2.response.forEach((item) => {
          if (item.type !== null) {
            if (item.type === "dropdown") {
              tempAllClientNameColumnListOptionsDropdownType.push({
                label: item.title,
                value: item.id,
                details: item.settings_str,
              });
            }
          }
        });

        tempAllClientNameColumnListOptionsDropdownType.forEach((detail) => {
          if (detail.value === profileData.clientNameColumn) {
            JSON.parse(detail.details).labels.map((subItem) => {
              tempOptionsClientName.push({
                label: subItem.name,
                value: subItem.name,
              });
            });
          }
        });

        setClientNameDropDownOptions(
          tempAllClientNameColumnListOptionsDropdownType
        );
        setClientNameOptions(tempOptionsClientName);
        setAllColumnOptions(tempAllColumns);
      }
    } catch (err) {
      console.log(err, "error");
    } finally {
      setLoading(false);
    }
  };

  const getAllCategoryListing = async () => {
    try {
      const response = await fetcher("governify/admin/dashboardata", "GET");
      if (response.status) {
        let tempCategory = [];
        response.response.forEach((item) => {
          tempCategory.push({
            label: item.category.title,
            value: item.category.id,
          });
        });
        setCategoryData(tempCategory);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleChangeCategory = (e) => {
    let tempProfileData = { ...profileData };
    tempProfileData.category = e;
    setProfileData(tempProfileData);
  };

  const handleChangeClientNameColumn = (e) => {
    let tempProfileData = { ...profileData };
    let tempOptionsClientName = [];
    tempProfileData.clientNameColumn = e;

    clientNameDropDownOptions.forEach((item) => {
      if (item.value === e) {
        JSON.parse(item.details).labels.map((subItem) => {
          tempOptionsClientName.push({
            label: subItem.name,
            value: subItem.name,
          });
        });
      }
    });
    setClientNameOptions(tempOptionsClientName);
    setProfileData(tempProfileData);
  };

  const handleChangeClientName = (e) => {
    let tempProfileData = { ...profileData };
    tempProfileData.clientName = e;
    setProfileData(tempProfileData);
  };

  useEffect(() => {
    getAllCategoryListing();
  }, []);

  useEffect(() => {
    fetchBoardData();
  }, []);

  return (
    <>
      <div style={{ width: "100%", marginTop: "25px" }}>
        <div>
          <div
            className="text-white"
            style={{ backgroundColor: data.head_title_color }}
          >
            <p
              className="p-2 m-0 fs-5"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <strong>Edit Profile</strong>
            </p>
          </div>
          <div className="form_wrapper border border-success p-4 primary-shadow">
            <div className="mt-10">
              <Input
                placeholder="Profile name"
                onChange={(e) => handleChangeProfileTitle(e)}
                addonBefore="Profile Name"
                value={profileData.profile_name}
              />
            </div>

            <div className="mt-10">
              <Select
                mode="multiple"
                allowClear
                showSearch
                placeholder={"Select User"}
                style={{ width: "100%", borderRadius: "10px" }}
                popupMatchSelectWidth={false}
                placement="bottomLeft"
                onChange={handleUserChange}
                options={userListing}
                value={profileData.users}
                filterOption={filterOption}
              />
            </div>

            <div className="mt-10">
              <Select
                allowClear
                showSearch
                placeholder={"Select Board"}
                style={{ width: "100%", borderRadius: "10px" }}
                popupMatchSelectWidth={false}
                placement="bottomLeft"
                onChange={handleBoardChange}
                options={allBoardsOptions}
                value={profileData.selectedBoard}
                filterOption={filterOption}
              />
            </div>

            <div className="mt-10">
              <Select
                allowClear
                showSearch
                placeholder={"Select Column"}
                style={{ width: "100%", borderRadius: "10px" }}
                popupMatchSelectWidth={false}
                placement="bottomLeft"
                onChange={handleColumnChange}
                options={allColumnOptions}
                value={profileData.selectedColumn}
                filterOption={filterOption}
              />
            </div>

            <div className="mt-10">
              <Input
                placeholder="Filter value"
                onChange={handleChangeFilterValue}
                addonBefore="Filter Value"
                value={profileData.selectedColumnValue}
              />
            </div>

            <div className="mt-10">
              <p style={{ marginBottom: "0px" }}>Category</p>
              <Select
                mode="multiple"
                allowClear
                showSearch
                placeholder={"Select Category"}
                style={{ width: "100%", borderRadius: "10px" }}
                popupMatchSelectWidth={false}
                placement="bottomLeft"
                onChange={handleChangeCategory}
                options={categoryData}
                value={profileData.category}
                filterOption={filterOption}
              />
            </div>

            <div className="mt-10">
              <p style={{ marginBottom: "0px" }}>
                Form Submission Setting Column
              </p>
              <Select
                allowClear
                showSearch
                placeholder={"Select Client Name Column"}
                style={{ width: "100%", borderRadius: "10px" }}
                popupMatchSelectWidth={false}
                placement="bottomLeft"
                onChange={handleChangeClientNameColumn}
                options={clientNameDropDownOptions}
                value={profileData.clientNameColumn}
                filterOption={filterOption}
              />
            </div>

            <div className="mt-10">
              <p style={{ marginBottom: "0px" }}>
                {" "}
                Form Submission Settings ClientName
              </p>
              <Select
                allowClear
                showSearch
                placeholder={"Select Category"}
                style={{ width: "100%", borderRadius: "10px" }}
                popupMatchSelectWidth={false}
                placement="bottomLeft"
                onChange={handleChangeClientName}
                options={clientNameOptions}
                value={profileData.clientName}
                filterOption={filterOption}
              />
            </div>

            <div className="mt-10">
              <ImageUpload
                onFileSelect={handleFileSelect}
                imageName={
                  profileData.image_name.length === 0
                    ? profileDataToEdit.logo
                    : profileData.image_name
                }
                imageUrl={
                  profileData.image.length === 0
                    ? profileDataToEdit.logo_location
                    : profileDataToEdit.logo
                }
              />
            </div>

            <Button
              className="mt-10"
              style={{
                background: data.button_bg,
                color: "#fff",
                border: "none",
              }}
              onClick={handleEditProfile}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
