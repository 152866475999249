import { Button, Modal, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer } from "react-toastify";
import CustomerFormDrawer from "./user/CustomerFormDrawer";
import { fetcher } from "../utils/helper";
import { MailSent } from "../utils/Icons";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

export const TabContent = ({
  details,
  categoryName,
  categoryId,
  accessProfileCategory,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState();
  const [serviceTitle, setServiceTitle] = useState("");
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedFormId, setSelectedFormId] = useState("");
  const [selectedServiceId, setSelectedServiceId] = useState("");
  const [selectedPortalId, setSelectedPortalId] = useState(null);
  const [portalCredentials, setPortalCredentials] = useState([]);
  const [portalImageAndLogo, setPortalImageAndLogo] = useState([]);
  const [showFormSubmissionModal, setShowFormSubmissionModal] = useState(false);

  let modalWidth = "650px";

  const settingsData = JSON.parse(sessionStorage.getItem("settings")) || {
    image:
      "https://onboardify.tasc360.com/uploads/governify/1718271730_1718195689_Products%20Logo%20(1).png",
    site_bg: "#ffffff",
    button_bg: "#5ac063",
    banner_bg: "#5ac063",
    banner_content:
      "Hire an attitude, not just experience and qualification. Greg Savage.",
    header_bg: "#f7f7f7",
    head_title_color: "#5ac063",
    form_description:
      "Please fill out the form to proceed with the needed action to provide you with this service",
  };

  const handleModalForm = (formData, title, serviceId, portalId) => {
    const formDetails = Object.entries(formData)[0][1];
    setFormData(formDetails);
    setSelectedFormId(formDetails.id);
    setServiceTitle(title);
    setSelectedServiceId(serviceId);
    setSelectedPortalId(portalId);
    setOpen(true);
  };

  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleOpen = () => {
    setOpen(false);
    setFormSubmitted(false);
  };

  const buttonStyle = {
    border: `1px solid ${settingsData.button_bg}`,
    color: settingsData.button_bg,
    transition: "all 0.3s ease",
    height: "41px",
    borderRadius: "10px",
    fontSize: "12px",
    fontWeight: "600",
    backgroundColor: "transparent", // Ensure the default background is transparent
  };

  const disabledButtonStyle = {
    border: `1px solid #858B937A`,
    color: "#858B937A",
    transition: "all 0.3s ease",
    height: "41px",
    borderRadius: "10px",
    fontSize: "12px",
    fontWeight: "600",
    background: "transparent",
  };

  const buttonHoverStyle = {
    backgroundColor: settingsData.button_bg,
    color: "#ffffff",
  };

  const isLargeScreen = useMediaQuery({ query: "(min-width: 1200px)" });
  const isMediumScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const isSmallScreen = useMediaQuery({ query: "(max-width: 767px)" });

  if (isLargeScreen) {
    modalWidth = "650px";
  } else if (isMediumScreen) {
    modalWidth = "650px";
  } else if (isSmallScreen) {
    modalWidth = "95%";
  }

  const navigateToTrackPage = () => {
    navigate("/track-request");
  };

  const fetchPortals = async () => {
    const url = "governify/customer/getPortalDetails";
    const method = "GET";
    try {
      const response = await fetcher(url, method);
      if (response.status) {
        setPortalImageAndLogo(response.response);
      }
    } catch (error) {
      console.log("error");
    }
  };

  const fetchPortalCredentials = async () => {
    let url = "governify/customer/getPortalCredentials";
    const method = "GET";
    try {
      const response = await fetcher(url, method);
      if (response.status) {
        setPortalCredentials(response.response);
      } else {
        setPortalCredentials([]);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchPortals();
    fetchPortalCredentials();
  }, []);

  return (
    <div key={uuidv4()}>
      <div className="service-parent-div">
        {details.map((item) => {
          const description = item.service_request.description;
          const isExpanded = expandedDescriptions[item.service_request.id];
          const truncatedDescription =
            description.length > 117
              ? description.substring(0, 80) + "..."
              : description;
          const imageLink = item.service_request.file_location;
          const title = item.service_request.title;

          return (
            <div className="service-repetitive-div" key={uuidv4()}>
              <div className="service-image-wrapper">
                <img
                  className="service-image"
                  src={imageLink}
                  alt="No Preview"
                />
              </div>
              <Typography className="service-child-title ">{title}</Typography>
              <Typography className="service-child-subtitle ">
                {isExpanded ? description : truncatedDescription}
                {description.length > 117 && !isExpanded && (
                  <span
                    className="read-more-btn"
                    onClick={() => toggleDescription(item.service_request.id)}
                    style={{ cursor: "pointer", color: "#4096ff" }}
                  >
                    {!isExpanded && (
                      <span style={{ whiteSpace: "nowrap" }}> Read More</span>
                    )}
                  </span>
                )}
              </Typography>

              { 
                
                (accessProfileCategory[categoryId] === undefined ||
                  !accessProfileCategory[categoryId].includes(
                    item.service_request.id
                  )) &&
                sessionStorage.getItem("governifyRole") !== "2"
                
                ? (
                <Tooltip
                  color="black"
                  placement="top"
                  title={
                    "Please contact the admin to request access for this action."
                  }
                >
                  <Button
                    key={title}
                    className="tabcontent-create-request-btn"
                    style={
                      (accessProfileCategory[categoryId] === undefined ||
                        !accessProfileCategory[categoryId].includes(
                          item.service_request.id
                        )) &&
                      sessionStorage.getItem("governifyRole") !== "2"
                        ? disabledButtonStyle
                        : buttonStyle
                    }
                    icon={
                      <PlusOutlined
                        style={{
                          color:
                            accessProfileCategory[categoryId] === undefined ||
                            !accessProfileCategory[categoryId].includes(
                              item.service_request.id
                            )
                              ? "#858B937A"
                              : settingsData.button_bg,
                        }}
                      />
                    }
                    onClick={() => {
                      if (sessionStorage.getItem("governifyRole") === "2") {
                        handleModalForm(
                          item.service_forms,
                          item.service_request.title,
                          item.service_request.id,
                          item.service_request.portal_credentials_id
                        );
                      } else {
                        if (
                          accessProfileCategory[categoryId] === undefined ||
                          !accessProfileCategory[categoryId].includes(
                            item.service_request.id
                          )
                        ) {
                        } else {
                          handleModalForm(
                            item.service_forms,
                            item.service_request.title,
                            item.service_request.id,
                            item.service_request.portal_credentials_id
                          );
                        }
                      }
                    }}
                    disabled={Object.keys(item.service_forms).length === 0}
                    onMouseEnter={(e) => {
                      if (sessionStorage.getItem("governifyRole") === "2") {
                        e.currentTarget.style.backgroundColor =
                          buttonHoverStyle.backgroundColor;
                        e.currentTarget.style.color = buttonHoverStyle.color;
                        e.currentTarget.querySelector(".anticon").style.color =
                          buttonHoverStyle.color;
                      } else {
                        if (
                          accessProfileCategory[categoryId] === undefined ||
                          !accessProfileCategory[categoryId].includes(
                            item.service_request.id
                          )
                        ) {
                        } else {
                          e.currentTarget.style.backgroundColor =
                            buttonHoverStyle.backgroundColor;
                          e.currentTarget.style.color = buttonHoverStyle.color;
                          e.currentTarget.querySelector(
                            ".anticon"
                          ).style.color = buttonHoverStyle.color;
                        }
                      }
                    }}
                    onMouseLeave={(e) => {
                      if (sessionStorage.getItem("governifyRole") === "2") {
                        e.currentTarget.style.backgroundColor = "transparent";
                        e.currentTarget.style.color = settingsData.button_bg;
                        e.currentTarget.querySelector(".anticon").style.color =
                          settingsData.button_bg;
                      } else {
                        if (
                          accessProfileCategory[categoryId] === undefined ||
                          !accessProfileCategory[categoryId].includes(
                            item.service_request.id
                          )
                        ) {
                        } else {
                          e.currentTarget.style.backgroundColor = "transparent";
                          e.currentTarget.style.color = settingsData.button_bg;
                          e.currentTarget.querySelector(
                            ".anticon"
                          ).style.color = settingsData.button_bg;
                        }
                      }
                    }}
                  >
                    Create a Request
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  key={title}
                  className="tabcontent-create-request-btn"
                  style={
                    (accessProfileCategory[categoryId] === undefined ||
                      !accessProfileCategory[categoryId].includes(
                        item.service_request.id
                      )) &&
                    sessionStorage.getItem("governifyRole") !== "2"
                      ? disabledButtonStyle
                      : buttonStyle
                  }
                  icon={
                    <PlusOutlined
                      style={{
                        color: (accessProfileCategory[categoryId] === undefined ||
                          !accessProfileCategory[categoryId].includes(
                            item.service_request.id
                          )) &&
                        sessionStorage.getItem("governifyRole") !== "2"
                          ? "#858B937A"
                          : settingsData.button_bg,
                      }}
                    />
                  }
                  onClick={() => {
                    // if (
                    //   !accessProfileCategory[categoryId].includes(
                    //     item.service_request.id
                    //   )
                    // ) {
                    // } else {
                      handleModalForm(
                        item.service_forms,
                        item.service_request.title,
                        item.service_request.id,
                        item.service_request.portal_credentials_id
                      );
                    // }
                  }}
                  disabled={Object.keys(item.service_forms).length === 0}
                  onMouseEnter={(e) => {
                    // if (
                    //   !accessProfileCategory[categoryId].includes(
                    //     item.service_request.id
                    //   )
                    // ) {
                    // } else {
                      e.currentTarget.style.backgroundColor =
                        buttonHoverStyle.backgroundColor;
                      e.currentTarget.style.color = buttonHoverStyle.color;
                      e.currentTarget.querySelector(".anticon").style.color =
                        buttonHoverStyle.color;
                    // }
                  }}
                  onMouseLeave={(e) => {
                    // if (
                    //   !accessProfileCategory[categoryId].includes(
                    //     item.service_request.id
                    //   )
                    // ) {
                    // } else {
                      e.currentTarget.style.backgroundColor = "transparent";
                      e.currentTarget.style.color = settingsData.button_bg;
                      e.currentTarget.querySelector(".anticon").style.color =
                        settingsData.button_bg;
                    // }
                  }}
                >
                  Create a Request
                </Button>
              )}
            </div>
          );
        })}
      </div>
      <ToastContainer position="bottom-right" className="custom-toast" />

      {open && (
        <CustomerFormDrawer
          open={open}
          formData={formData}
          serviceTitle={serviceTitle}
          portalId={selectedPortalId}
          portalImageAndLogo={portalImageAndLogo.filter(
            (item) => item.id === selectedPortalId
          )}
          fetchPortalCredentials={fetchPortalCredentials}
          portalCredentials={portalCredentials.filter(
            (item) => item?.portal_credential_id === selectedPortalId
          )}
          setOpen={setOpen}
          categoryName={categoryName}
          key={uuidv4()}
          handleOpen={handleOpen}
          formSubmitted={formSubmitted}
          setFormSubmitted={setFormSubmitted}
          categoryId={categoryId}
          selectedFormId={selectedFormId}
          selectedServiceId={selectedServiceId}
          setShowFormSubmissionModal={setShowFormSubmissionModal}
        />
      )}

      <Modal
        width={modalWidth}
        open={showFormSubmissionModal}
        zIndex={9999}
        closeIcon={
          <CloseOutlined
            className="fs-18"
            style={{ color: "#202223", marginTop: "5px" }}
          />
        }
        onCancel={() => setShowFormSubmissionModal(false)}
        footer={
          <div
            className="text-center pt-3 pb-2"
            style={{
              borderTop: "1px solid #858B932E",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ gap: "10px", width: "50%", margin: "auto" }}>
              <button
                className="px-3 py-2 fs-16"
                style={{
                  width: "100%",
                  border: "none",
                  borderRadius: "8px",
                  fontWeight: "600",
                  background: "#00bf63",
                  color: "#fff",
                }}
                onClick={navigateToTrackPage}
              >
                Track My Request
              </button>
            </div>
            <div
              style={{
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "10px",
              }}
            >
              <button
                className="px-3 py-2 fs-16"
                style={{
                  width: "100%",
                  border: "none",
                  background: "#E0f7ec",
                  borderRadius: "8px",
                  fontWeight: "600",
                  color: "#00bf63",
                }}
                onClick={() => {
                  setShowFormSubmissionModal(false);
                  // setOpen(true);
                  setTimeout(() => {
                    if (!open) {
                      setOpen(true);
                    }
                  }, 500);
                }}
              >
                Submit another request
              </button>
            </div>
          </div>
        }
        centered
      >
        <div>
          <div className="text-center mt-4">
            <MailSent />
          </div>
          <div className="text-center fs-20 mt-2" style={{ fontWeight: 600 }}>
            Request Submitted Successfully.
          </div>
          <div
            className="text-center fs-16 mt-3"
            style={{ color: "#6D7175", maxWidth: "560px" }}
          >
            We have received your request successfully! you can submit another
            request if you would like to :)
          </div>
        </div>
      </Modal>
    </div>
  );
};
