import { Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Smartphone } from "react-feather";
import OtpInput from "./OtpInput";
import { fetcher } from "../../../utils/helper";
import { toast } from "react-toastify";
import { Loader } from "../../common/Loader";
import { getLoginUserDetails } from "../../../utils/tools";

const MobileVerification = ({
  open,
  setOpenOtpModal,
  verifying,
  setVerify,
  number,
}) => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [mobNumber, setMobNumber] = useState({
    code: "",
    number: "",
  });
  const inputRefs = useRef([]);

  const handleChangeOtp = (value, index) => {
    if (value.match(/^\d$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];

      if (otp[index] === "" && index > 0) {
        inputRefs.current[index - 1].focus();
      } else if (otp[index] !== "") {
        newOtp[index] = "";
        setOtp(newOtp);
      }
    }
  };

  const updateNumber = async () => {
    let url = "governify/customer/mobileNumberUpdate";
    let method = "POST";
    let payload = JSON.stringify({
      phone_code: mobNumber.code,
      phone_number: mobNumber.number,
    });
    try {
      const res = await fetcher(url, method, payload);
      if (res.status) {
        // toast.success(res.message);
        await getLoginUserDetails();
        window.location.reload();
      } else {
        // toast.error(res.message);
      }
    } catch (error) {}
  };

  const handleSubmit = async () => {
    let valid = true;
    otp.forEach((item) => {
      if (item === "") {
        valid = false;
      }
    });

    if (!valid) {
      setError(true);
      return;
    } else {
      setError(false);
    }
    setLoadingConfirm(true);
    try {
      const res = await fetcher(
        "governify/customer/verifyOTP",
        "POST",
        JSON.stringify({
          code: otp.join(""),
          recipient: mobNumber.code + mobNumber.number,
        })
      );

      if (res.status) {
        await updateNumber();
        setErrMsg("");
        setError(false);
        sessionStorage.removeItem("otp");
        toast.success(res.message);
        localStorage.setItem("verified", true);
      } else {
        setErrMsg(
          "This is a wrong or expired code. Try to resend another code."
        );
        setError(true);
      }
    } catch (error) {
      console.log("error");
    } finally {
      setLoadingConfirm(false);
    }
  };

  useEffect(() => {
    if (number) {
      setMobNumber({ code: number.phoneCode, number: number.phoneNumber });
    }
  }, [number]);

  const sendVerification = async () => {
    setVerify(false);
    setErrMsg("");
    setError(false);
    const res = await fetcher(
      "governify/customer/sendOTP",
      "POST",
      JSON.stringify({
        channel: "sms",
        recipient: mobNumber.code + mobNumber.number,
        username: sessionStorage.getItem("userName"),
        header_bg: "#00BF63",
        domain_logo: "https://governify.tasc360.com/assets/governify_logo.png",
        cred_logo: "https://governify.tasc360.com/assets/credentials.png",
        youtube_logo: "https://governify.tasc360.com/assets/youtube.png",
        linkedin_logo: "https://governify.tasc360.com/assets/linkedin.png",
        facebook_logo: "https://governify.tasc360.com/assets/facebook.png",
        insta_logo: "https://governify.tasc360.com/assets/instagram.png",
        email_logo: "https://governify.tasc360.com/assets/email.png",
        website_logo: "https://governify.tasc360.com/assets/website.png",
        phone_logo: "https://governify.tasc360.com/assets/phone.png",
      })
    );

    if (res.success) {
      sessionStorage.setItem("otp", true);
    } else {
      toast.error('Something went wrong while sending OTP, please retry again');
    }

    console.log("res", res);
  };

  useEffect(() => {
    if (verifying && open) {
      sendVerification();
    }
  }, [verifying, open]);

  return (
    <>
      {loadingConfirm && <Loader />}
      <Modal
        open={open}
        centered
        onCancel={() => setOpenOtpModal(false)}
        footer={null}
        zIndex={9993}
      >
        <div
          className="d-flex flex-column align-items-center text-center pt-4 pb-2 px-2"
          style={{ gap: "20px" }}
        >
          <Smartphone
            style={{ color: "#00BF63", width: "48px", height: "36px" }}
          />
          <div className="d-flex flex-column" style={{ gap: "20px" }}>
            <div className="d-flex flex-column" style={{ gap: "5px" }}>
              <div className="fs-24 fw-bold" style={{ color: "#202223" }}>
                Verify Mobile Number
              </div>
              <div className="fs-16 text-nevada">
                Enter your 6-digit code numbers sent to you at {mobNumber.code}
                {mobNumber.number}
              </div>
            </div>
            <div className="d-flex justify-content-between">
              {Array(6)
                .fill("")
                .map((_, index) => {
                  return (
                    <OtpInput
                      otp={otp}
                      inputRef={(el) => (inputRefs.current[index] = el)}
                      index={index}
                      setOtp={setOtp}
                      handleChangeOtp={handleChangeOtp}
                      handleKeyDown={handleKeyDown}
                      error={error}
                    />
                  );
                })}
            </div>
            <div className="d-flex flex-column" style={{ gap: "5px" }}>
              {errMsg && <div style={{ color: "#EF4444" }}>{errMsg}</div>}
              <div className="fs-16 text-nevada">
                Didn't receive a verification code?
              </div>
              <span
                className="fs-16"
                style={{ color: "#059669", cursor: "pointer" }}
                onClick={() => sendVerification()}
              >
                Resend Code
              </span>
            </div>
            <div className="w-100">
              <button
                style={{
                  background: "#00BF63",
                  color: "#fff",
                  borderRadius: "8px",
                  gap: "5px",
                  padding: "10px 20px",
                }}
                className={`border-0 d-flex fs-16 fw-semibold align-items-center w-100 justify-content-center`}
                onClick={() => handleSubmit()}
              >
                Verify
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MobileVerification;
